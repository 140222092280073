<template>
  <section class="our-house-models">
    <section class="hero-small">
      <div>
        <h1>Un large choix de modèles de maisons</h1>
      </div>
    </section>
    <div class="grid-container">
      <div class="intro">
        <h3>
          Esthétique, urbanisme, normes,
          <router-link to="/maisons/mode-constructif">mode constructif</router-link>, plan,
          orientation… choisir sa maison dépend de beaucoup de facteurs mais c’est avant tout les
          limites de son budget qui constitue en général la plus grosse contrainte. Maisons Alysia
          vous propose le meilleur de la maison au meilleur prix car même à budget réduit, on a le
          droit d’avoir du choix !
        </h3>
      </div>

      <div class="grid-x row grid-margin-x house-models">
        <div class="cell">
          <div class="card-house-models">
            <div>
              <img src="@/assets/img/pages/house-model-1.jpg" alt="Le choix à tous les niveaux" />
            </div>
            <div>
              <h3>Le choix à tous les niveaux</h3>
              <p>
                La majorité des Français préfère
                <router-link to="/maisons/immobilier-pourquoi-les-maisons-de-plain-pied-ont-la-cote">
                les avantages d’une maison de plain-pied</router-link>. Mais, si vous avez un petit
                terrain ou si vous voulez optimiser vos espaces intérieurs, adoptez
                <router-link to="/maisons/etage">la solution maison à étage</router-link>. Notre catalogue
                de maisons saura répondre à vos besoins, quelque soit votre préférence.
              </p>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="card-house-models">
            <div>
              <img
                src="@/assets/img/pages/house-model-2.jpg"
                alt="Des maisons modernes ou plus traditionnelles"
              />
            </div>
            <div>
              <h3>Des maisons modernes ou plus traditionnelles</h3>
              <p>
                Que vous aimiez un <router-link to="/maisons/contemporaine">style de maison contemporain</router-link> ou
                plus traditionnel, nous proposons régulièrement de nouveaux modèles pour élargir la
                palette de votre choix.
              </p>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="card-house-models">
            <div>
              <img
                src="@/assets/img/pages/house-model-3.jpg"
                alt="Tuiles, ardoises, mono-pentes ou toit plat"
              />
            </div>
            <div>
              <h3>Tuiles, ardoises, mono-pentes ou toit plat</h3>
              <p>
                Un large éventail de couvertures qui saura répondre aux contraintes de style liées à
                votre région comme la tuile au Sud de la Loire et l’Ardoise au Nord. Mais pour ceux
                qui préfèrent <router-link to="/maisons/moderne">une maison au style moderne</router-link>, le toit
                plat ou mono-pente sera une bonne alternative.
              </p>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="card-house-models">
            <div>
              <img src="@/assets/img/pages/house-model-4.jpg" alt="Des plans en formes" />
            </div>
            <div>
              <h3>Des plans en formes</h3>
              <p>
                <!-- eslint-disable-next-line no-irregular-whitespace -->
                Découvrez nos différents plans de maison : en « U », en « L », en «  T » ou
                rectangulaire pour plus d’économies. Quel que soit leur complexité, découvrez nos
                <router-link to="/maisons/plan-maison-intelligent">plans de maison intelligents</router-link>
                qui grâce à notre configurateur s’adaptent automatiquement au terrain.
              </p>
            </div>
          </div>
        </div>
        <div class="cell">
          <div class="card-house-models">
            <div>
              <img src="@/assets/img/pages/house-model-5.jpg" alt="Un modèle, des versions" />
            </div>
            <div>
              <h3>Un modèle, des versions</h3>
              <p>
                Chacun de nos modèles est décliné en plusieurs versions. Cette diversité vous
                permets de choisir le nombre de chambres (2, 3 ou 4, parfois même 5), plusieurs
                surfaces pour arbitrer entre confort et budget, la nature des pièces (bureau,
                cellier, 2de salle de bain…) ou leur emplacement (cuisine coté jardin ou côté
                entrée). Vous allez vous régaler dans notre configurateur…
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-x row grid-margin-x find-house">
        <div class="cell">
          <div class="card-find-house">
            <figure>
              <img
                src="@/assets/img/pages/house-model-6.jpg"
                alt="Configurez facilement votre projet maison"
              />
            </figure>
            <div>
              <h2>Configurez facilement votre projet maison</h2>
              <ul>
                <li>Découvrez tout notre catalogue</li>
                <li>Configurez vos finitions et équipement</li>
                <li>Obtenez votre chiffrage complet.</li>
              </ul>
              <router-link :to="{ name: 'home', hash: '#hero' }" class="link-button"><app-button theme="primary" hasArrow>Commencer</app-button></router-link>
              <router-link :to="{ name: 'configurator' }">En savoir plus</router-link>
            </div>
          </div>
        </div>
      </div>

      <frame-cards />
    </div>
  </section>
</template>

<script>
import FrameCards from '@/components/general/FrameCards.vue';

export default {
  name: 'our-house-models',
  components: {
    FrameCards,
  },
};
</script>

<style lang="sass">
.our-house-models
  padding: 0 0 4rem
  background: $medium-bg
  overflow-x: hidden
  .hero-small
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/where-to-build-a-house.jpg)

  .intro
    margin: 6rem auto 5rem
    padding: 0 1rem
    max-width: 925px
    text-align: center

    a
      @include hyperlink
      text-decoration: none
      font-size: inherit

  .house-models > .cell:not(:last-child)
    margin-bottom: 1.25rem

  .card-house-models
    @include card-house-models

  .find-house
    margin-top: 5rem

  .card-find-house
    @include card-find-house

  @media (max-width: 768px)
    .intro
      margin: 4rem auto

    .house-models > .cell:not(:last-child)
      margin-bottom: 1rem

    .card-find-house
      height: fit-content

      figure
        display: none
      div
        margin-top: 0

    .find-house
      margin-top: 1.5rem
</style>
